import React, { useEffect } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';

const Contact = ({ backgroundForm }) => {

  //const alert = useAlert();

  /*const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = { 
      name: name,
      phone: phone,
      email: email,
      message: message,
    };
    axios.post('https://api.chemodanchik.online/form', data)
      .then(response => console.log(response));

    if (name && phone && email && message) {
  
      setName('');
      setPhone('');
      setEmail('');
      setMessage('');
      alert.show('Ваша заявка успішно відправлена');
    }
  };*/

  useEffect(() => {
    const parent = document.getElementById("bitrix-form");
    const script = document.createElement("script");
    script.dataset.b24Form = "inline/3/28vs6x";
    script.dataset.skipMoving = "true"
    script.innerHTML = "(function(w,d,u){ var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0); var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h); })(window,document,'https://cdn.bitrix24.ua/b10279791/crm/form/loader_3.js');";

    parent.appendChild(script);
  }, []);

  return (
    <>
      <div
        id="contact"
        className={
          backgroundForm
            ? 'gtco-section border-bottom contact blue contact-section'
            : 'gtco-section border-bottom contact contact-section'
        }
      >
        <div className="gtco-container">
          <div className="row">
            <div className="col-md-12">
              <div className=
                {
                  backgroundForm
                    ? "col-md-6 animate-box contact-background transparent"
                    : "col-md-6 animate-box contact-background"
                }
                id="bitrix-form"
              >
                {/*<h3>Залишити заявку</h3>
                <p>Це безкоштовно і не зобов'язує Вас до бронювання</p>
                <form onSubmit={handleSubmit}>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="sr-only" htmlFor="name">Ім'я</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Ваше ім'я"
                        value={name}
                        required
                        onChange={event => setName(event.target.value.trimLeft())}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="sr-only" htmlFor="phone">Phone</label>
                      <input
                        type="phone"
                        id="phone"
                        className="form-control"
                        placeholder="Номер телефону"
                        value={phone}
                        required
                        onChange={event => setPhone(event.target.value.trimLeft())}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="sr-only" htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Пошта"
                        value={email}
                        required
                        onChange={event => setEmail(event.target.value.trimLeft())}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="sr-only" htmlFor="message">Повідомлення</label>
                      <textarea
                        name="message"
                        id="message"
                        cols="30" rows="10"
                        className="form-control"
                        placeholder='Ваше повідомлення&#10;(Яка країна, скільки людей планує на відпочинок, орієнтовні дати, бюджет та додаткові побажання)'
                        required
                        value={message}
                        onChange={event => setMessage(event.target.value.trimLeft())}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      value="Отправить"
                      className="btn btn-primary"
                    >
                      Надіслати
                    </button>
                  </div>
                </form>
		*/}
              </div>
              <div className="col-md-5 col-md-push-1 animate-box" style={{ paddingTop: "20px" }}>
                <div
                  className={
                    backgroundForm
                      ? "gtco-contact-info contact-background transparent"
                      : "gtco-contact-info contact-background"
                  }
                >
                  <h3>Контакти</h3>
                  <ul>
                    <li className="phone">
                      <a target="_blank" rel="noreferrer" href="tel:+380665767992">
                        +380665767992 (Viber)<br />
                        +380688846292
                      </a>
                    </li>
                    <li className="email">
                      <a target="_blank" rel="noreferrer" href="mailto:chemodanchikkiev@gmail.com">
                        chemodanchikkiev@gmail.com
                      </a>
                    </li>
                  </ul>{/*
                <h3>Адреса</h3>
                <ul>
                  <li className="address">
                    <a 
                      rel="noreferrer" target="_blank" 
                      href="https://www.google.com/maps/dir//%D0%A2%D1%83%D1%80%D0%B8%D1%81%D1%82%D0%B8%D1%87%D0%BD%D0%B5+%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%81%D1%82%D0%B2%D0%BE+%22%D0%A7%D0%B5%D0%BC%D0%BE%D0%B4%D0%B0%D0%BD%D1%87%D0%B8%D0%BA%22,+%D0%9A%D0%B8%D1%97%D0%B2/data=!4m8!4m7!1m0!1m5!1m1!1s0x40d4d10a83a848e5:0x274446399288c764!2m2!1d30.5240516!2d50.4941532"
                    >
                      Оболонська набережна, 7, корпус 1,<br />м. Київ, 04211
                    </a>
                  </li>
                </ul>*/}
                  <h3>Графік роботи</h3>
                  <ul>
                    <li className="address">
                      <a>
                        пн-пт:	10:00–19:00<br />сб: 10:00-15:00
                      </a>
                    </li>
                  </ul>
                  <h3>Соціальні мережі</h3>
                  <ul className="contact-links">
                    <li className="first-link">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://www.instagram.com/chemodanchik_kiev/"
                      >
                        <i className="fab fa-instagram contact-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://www.facebook.com/chemodanchik.kiev/"
                      >
                        <i className="fab fa-facebook contact-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://invite.viber.com/?g2=AQBzF3HAGo6whEmnEUU7qPkoez6djRaSLwZjsPX0KuDNgjLqZ3kMoMRNL94k6JIk"
                      >
                        <i className="fab fa-viber contact-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://t.me/chemodanchikkiev">
                        <i className="fab fa-telegram-plane contact-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://g.page/chemodanchik-kiev/review?rc">
                        <i className="fab fa-google contact-icon"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact;
